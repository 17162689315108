.category-list td {
  padding: 8px 16px !important;
}
.category-list td .category-title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.extra-layout-label {
  height: 32px;
  line-height: 32px;
  text-align: right;
  padding-right: 8px;
}
